export function formatDate(date) {
  const dateObj = new Date(date)
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]

  const year = dateObj.getFullYear()
  const month = monthNames[dateObj.getMonth()]
  const day = dateObj.getDate().toString().padStart(2, '0')

  return `${month} ${day}, ${year}`
}

export function makeSafeForCSS(name) {
  return name.replace(/[^a-z0-9]/g, function (s) {
    const c = s.charCodeAt(0)
    if (c === 32) return '-'
    if (c >= 65 && c <= 90) return `${s.toLowerCase()}`
    return `-${`000${c.toString(16)}`.slice(-4)}`
  })
}
