import React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { formatDate } from '../utility/helpers'
import * as teaserStyles from '../styles/modules/teaser.module.scss'

const Teaser = ({ single, alias, image, alt, title, author, created, summary }) => (
  <Link to={alias} className={teaserStyles.teaser}>
    <div className={teaserStyles.image}>
      <GatsbyImage image={image} alt={alt || ''} />
    </div>
    <div className={`${teaserStyles.info} ${single ? teaserStyles.single : ''}`}>
      {single ? <h3>{title}</h3> : <h2> {title} </h2>}
      {author ? (
        <p>
          By {author} | {formatDate(created)}
        </p>
      ) : null}
      <p>{summary}</p>
    </div>
  </Link>
)

export default Teaser
